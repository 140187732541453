.servicesmanpowerConatiner{
    /* background-color: rgba(238, 247, 253, 0.295) ; */
    /* background: linear-gradient(to right,   rgb(242, 248, 249) , rgb(246, 249, 251),rgb(236, 249, 252) ,rgb(230, 253, 255)); */
    background: linear-gradient(to right,   rgba(92, 167, 190, 0.329),rgba(115, 116, 119, 0.379),rgba(99, 123, 123, 0.119));

}
.servicesmanpowerBox{
    /* background: linear-gradient(to right,   rgb(22, 21, 30),rgb(42, 40, 54),rgb(33, 30, 48)); */
    background-color: white;
    color: rgb(0, 0, 0);
     border-radius: 5px;
     box-shadow: 1px 1px 10px rgb(0, 0, 0);


}

.servicesmanpowerBox:hover{
    box-shadow: 1px 1px 50px rgb(0, 0, 0);

}

.servicesMangeServiceBox p{
font-size: 1.2rem;
}

.servicesmanpowerBox{
    font-size: 1.1rem;
}