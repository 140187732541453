.quiz-container {
    /*margin-top: 50px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80vh;
    align-items: center;
}

.quiz-card {
    padding: 20px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.quiz-progress {
    height: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.quiz-question {
    font-weight: bold;
    margin-bottom: 20px;
}

.quiz-option {
    margin: 5px 0;
    font-size: 16px;
}

.quiz-result {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
}

.quiz-next-btn {
    margin-top: 20px;
    width: 100%;
}