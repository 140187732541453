
.content {
    font-family: 'Poppins', sans-serif;
    text-align: start;
   
}

.content h1{
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    text-align: start;
    font-weight: 400;
}
.content p {
    margin-left: 3%;
}
.careerInternTitle {
    margin-top: 20px;
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: rgb(18, 24, 125);
}
.content {
    padding: 2%;
}
.careerContainer {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 10%;
}

.careerForm {
    margin-top: 20px;
    padding: 5%;
}

.careerFormInput input,.careerFormInput select,.careerFormInput textarea{
    width: 100%;
    border: 2px solid gray;
    padding: 10px;
    text-align: start;
    margin-top: 2%;
    border-radius: 0;
    
}

.careerFormInput #firstName,.careerFormInput #lastName {
    width: 120%;
}
.careerFormInput .careername {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 12%;

}


@media (max-width: 800px) {
    .careerContainer{
      grid-template-columns: 1fr;
      
    }
   }

.upload-resume {
    display: inline-block;
    position: relative;
    width: 40%;
    margin-right: 60%;
    margin-top: 10px;
}

.careerFormInput #resume{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    background-color: blue;
}
.careerFormInput .resumeButton {
    display: inline-block;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.292);
  border: 2px solid rgba(128, 128, 128, 0.292);
  padding: 3%;
}

.careerFormInput .submitButton{
    width: 100%;
    background-color: rgb(42, 30, 130);
    margin-top: 10px;
    padding: 8px;
    border:2px solid rgb(42, 30, 130);
    color: white;
    transition: .9s ease;
}

.careerFormInput .submitButton:hover {
    color: rgb(77, 26, 121);
    background-color: white;
}

/* 
.careerContainer {
    background-image: url('/Image/Career/careerImage.jpg');
 
  } */
  