.headerNavbar{
    
    font-family: 'Poppins', sans-serif;
    color: rgb(13, 21, 252);
    box-shadow: 1px 1px 1px rgb(239, 238, 238);
}
.headerNavbarheading{

    color: rgb(37, 6, 151);
    font-size: 1.6rem;
}
.headerNavbarheading:hover{
    color: rgb(30, 35, 175);
}

@media  (max-width: 350px) {
    .headerNavbarheading{
        font-size: 1.3rem;
    }
}
/* Change text color on hover */
.headerNavbar .nav-link {


    font-size: 1.1rem;
    color: black;
    font-family: 'Poppins', sans-serif;
    text-align: start;
    font-weight: 500;

}

.headerNavbar .nav-link:hover {
    color: rgb(60, 0, 255);
     /* Change text color to red on hover */
}

.headerNavbar .dropdown-item:hover {
    color: red; /* Change dropdown item text color to red on hover */
}
/* Optional: Ensure dropdown is hidden by default */
.dropdown-menu {
   /* Hide dropdown menu by default */
border: 0px white
;}


/* Show dropdown on hover */
/* .dropdown:hover .dropdown-menu {
    display: block; 
} */

/* Optional: Add a custom icon */
.headerNavbar .navbar-toggler-icon:before {
    content: "☰"; /* You can use any icon or font icon here */
    font-size: 24px; /* Adjust size as needed */
    color: rgb(255, 255, 255); /* Change icon color */
}
