.footer-container {
  background-color: #ffffff; /* Light background */
}

.footer-container h5 {
  font-weight: bold;
}

.footer-container ul {
  padding-left: 0; /* Remove default list padding */
}

.footer-container ul li {
  list-style-type: none;
  margin-bottom: 0.5rem;
}

.footer-container ul li a {
  color: #000; /* Set link color */
  text-decoration: none; /* Remove underline from links */
  font-size: 1.1rem;
}

.footer-container ul li a:hover {
  color: #ff0000; /* Change link color on hover */
  text-decoration: none;

}

.footer-container p {
  margin-bottom: 0;
  color: #6c757d;
}
