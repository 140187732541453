.servicesHeading{
color: rgb(59, 48, 207);
}

.servicesKey{
    /* background-color:rgb(230, 255, 241)  ; */
    /* background: linear-gradient(to right,   rgb(237, 242, 255) , rgb(211, 229, 252),rgb(226, 224, 255) ,rgb(210, 232, 255)); */
}   
.servicesBenifits{
    /* background-color: rgba(238, 247, 253, 0.295) ; */
    /* background: linear-gradient(to right,   rgb(230, 249, 231) , rgb(211, 252, 223),rgb(224, 255, 226) ,rgb(210, 255, 210)); */
    background-color: #EDF1F3;

}

.servicesdevopsKey{
    /* background-color: rgba(95, 119, 227, 0.537);  */
    background-color: white;
    /* background: linear-gradient(to right,   rgb(245, 217, 255) , rgb(224, 159, 252) ,rgb(241, 227, 255)); */
    /* background: linear-gradient(to right,   rgb(210, 0, 56) , rgb(255, 0, 47) ,rgb(255, 62, 62)); */

/* color: white; */
    border-bottom: 10px solid ;   
    border-radius: 0px;
    /* border-radius: 20px; */
    background-color: #EDF1F3;

}

.servicesdevopsBenifts{
    background-color: white;
    border-radius: 10px;
}

.servicesdevopsKey p,.servicesdevopsBenifts p{
    font-size: 1.1rem;
}